import moment from 'moment';
import { dateTimeISODate, dateTimeMaskTimestamp } from './constants';

type SensorDataDetails = {
    sensorData?: any[],
    leaseStartTimestamp?: string,
    leaseEndTimestamp?: string,
    baseLeaseUntil?: string,
    shipmentEnd?: string,
    from?: string,
    to?: string,
    tempIndex?: number,
}
export const trimSensorData = ({
    sensorData,
    leaseStartTimestamp,
    leaseEndTimestamp,
    shipmentEnd,
    from,
    to,
    baseLeaseUntil,
    tempIndex = null,
}: SensorDataDetails) => {
    if (!sensorData || sensorData.length === 0) {
        return [];
    }
    const itemStep = 10 * 60 * 1000;
    const nullObj = sensorData?.[0]?.d.map(() => null) || [];
    const sensorDataFirstDate = new Date(sensorData?.[0]?.t);
    const sensorDataLastDate = new Date(sensorData[sensorData.length - 1].t);
    const startArr = [];
    const finishArr = [];

    let globalToMillis;

    let globalFromMillis;

    if (from) {
        globalFromMillis = moment(from, 'YYYY-MM-DDTHH:mm')
            .utc(false).valueOf();
    } else {
        globalFromMillis = moment(leaseStartTimestamp, 'DD.MM.YYYY HH:mm Z')
            .utc(false).valueOf();
    }

    if (to) {
        globalToMillis = moment(to, 'YYYY-MM-DDTHH:mm').utc(false).valueOf();
    } else if (shipmentEnd) {
        globalToMillis = moment(shipmentEnd, 'DD.MM.YYYY HH:mm:ss ZZ').utc(true).valueOf();
    } else if (leaseEndTimestamp) {
        globalToMillis = moment(leaseEndTimestamp, 'DD.MM.YYYY HH:mm ZZ').utc(false).valueOf();
    } else {
        globalToMillis = moment(baseLeaseUntil, dateTimeMaskTimestamp).utc(false).valueOf();
    }

    if (globalFromMillis + itemStep < sensorDataFirstDate.getTime()) {
        startArr.push({
            t: new Date(globalFromMillis - 60 * 60 * 1000).toISOString(),
            d: nullObj,
        });
    }

    if (sensorDataLastDate.getTime() + itemStep < globalToMillis) {
        finishArr.push({
            t: moment(globalToMillis + new Date().getTimezoneOffset() * 60 * 1000).utc(false).format(dateTimeISODate),
            d: nullObj,
        });
    }

    const resultingArray = [
        ...startArr,
        ...sensorData,
        ...finishArr,
    ];

    if (tempIndex) {
        let lastIndex = resultingArray.length - 1;

        while (lastIndex >= 0 && resultingArray[lastIndex].d[tempIndex] === null) {
            lastIndex -= 1;
        }

        return resultingArray.slice(0, lastIndex + 1);
    }
    return resultingArray;
};

// Apex chart draws the last points as 0 when they are null, so we remove it
// e.g. [1, null, 2, 3, null, null] => [1, null, 2, 3]
export const trimTemperatureReadoutSensorData = (sensorData: any[]) => {
    if (!sensorData || sensorData.length === 0) {
        return [];
    }

    const updatedSensorData = [...sensorData];

    while (updatedSensorData.length > 0 && updatedSensorData[updatedSensorData.length - 1].d[0] === null) {
        updatedSensorData.pop();
    }

    return updatedSensorData;
};

export const momentAddOffset = (date: moment.Moment) => {
    return date.subtract({ minute: new Date().getTimezoneOffset() });
};

export const utcDateToEpoch = ({ date, mask = 'YYYY-MM-DDTHH:mm', isUTC = true }) => moment(date, mask)
    .utc(!isUTC)
    .subtract({ minute: new Date().getTimezoneOffset() })
    .valueOf();

export const encodeObjToQuery = (obj: object) => {
    const linkArray = [];

    Object.keys(obj).forEach(key => {
        const val = obj[key];

        if (val instanceof Array) {
            linkArray.push(val.map(it => `${key}=${it}`).join('&'));
        } else if (val instanceof Object) {
            linkArray.push(encodeObjToQuery(val));
        } else {
            linkArray.push(`${key}=${val}`);
        }
    });

    return linkArray.join('&');
};

export const CACHE_TIME = 5 * 60 * 1000;
