import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles(() => ({
    wrapper: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    color: {
    },
}));

type Props = {
    status: any,
    error?: string,
}

const StatusInfo = ({
    status,
    error = null,
} : Props) => {
    const classes = useStyles();

    if (status === 'PENDING' || status === 'INIT') {
        return (
            <div className={classes.wrapper}>
                <CircularProgress
                    style={{
                        color: '#009CCF',
                    }}
                />
            </div>
        );
    }

    return (
        <>
            <Alert severity="error">
                {error || 'Some error happened'}
            </Alert>
        </>
    );
};

export default StatusInfo;
