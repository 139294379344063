import { ApexOptions } from 'apexcharts';
import { useCallback, useMemo } from 'react';
import { interpolateExtremums } from './lib';

type Props = {
    enabled: boolean,
    temperatureData: any,
    temperatureRangeMin: any,
    temperatureRangeMax: any,
    inLocalTimeZone: any,
    tempIndex?: number,
}

const useExcursionSeries = ({
    enabled,
    temperatureData,
    temperatureRangeMin,
    temperatureRangeMax,
    inLocalTimeZone,
    tempIndex = 0,
}: Props) => {
    // 1 out of Max, -1 out of Min, 0 in range
    const rangeType = useCallback((temp) => {
        if (temp < temperatureRangeMin) return -1;
        if (temp > temperatureRangeMax) return 1;
        return 0;
    }, [temperatureRangeMax, temperatureRangeMin]);

    return useMemo<ApexOptions['series']>(() => {
        if (enabled) {
            const tempDataWithIndexes = temperatureData.map(({ t, d }, i) => ({ t, d, i }));
            const bottomInterpolation = interpolateExtremums({
                data: tempDataWithIndexes
                    .filter(({ d }) => d[tempIndex] !== null && (d[tempIndex] <= temperatureRangeMin)),
                inLocalTimeZone,
                tempIndex,
                temperatureData,
                extremumTemp: temperatureRangeMin,
                rangeChecker: (currentT) => rangeType(currentT) === -1,
            });
            const topInterpolation = interpolateExtremums({
                data: tempDataWithIndexes
                    .filter(({ d }) => d[tempIndex] !== null && (d[tempIndex] >= temperatureRangeMax)),
                inLocalTimeZone,
                tempIndex,
                temperatureData,
                extremumTemp: temperatureRangeMax,
                rangeChecker: (currentT) => rangeType(currentT) === 1,
            });

            return [bottomInterpolation.length > 0 ? {
                name: 'Excursion',
                type: 'rangeArea',
                color: '#B92C2C',
                opacity: 0.2,
                data: bottomInterpolation,
                stroke: {
                    width: 2,
                },
            } : null,
            topInterpolation.length > 0 ? {
                name: 'Excursion',
                type: 'rangeArea',
                color: '#B92C2C',
                opacity: 0.2,
                data: topInterpolation,
                stroke: {
                    width: 2,
                },
            } : null].filter(it => it);
        } else return [];
    }, [
        enabled,
        temperatureData,
        temperatureRangeMin,
        rangeType,
        temperatureRangeMax,
        inLocalTimeZone,
    ]);
};

export default useExcursionSeries;
