import React, { ChangeEvent } from 'react';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    showMarkersSwitchContainer: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        color: '#747474',
        opacity: 1,
    },
    small: {
        font: 'normal normal normal 12px/17px Roboto',
        letterSpacing: '0.43px',
    },
    medium: {
        font: 'normal normal normal 14px/21px Roboto',
        letterSpacing: '0.49px',
    },
    switchLabel: {
        margin: '0 5px 0 13px',
    },
    switchFirstLabel: {
        marginLeft: '5px',
    },
});

type Props = {
    title: string,
    value: boolean,
    onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void,
    className?: string,
    color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default',
    disabled?: boolean,
    size?: 'small' | 'medium',
    switchFirst?: boolean,
    switchClassName?: string,
}

const SwitchWithLabel = ({
    title,
    value,
    onChange,
    className = '',
    color = 'primary',
    disabled = false,
    size = 'medium',
    switchFirst = false,
    switchClassName = '',
}: Props) => {
    const classes = useStyles();

    const containerClass = `${classes.showMarkersSwitchContainer} ${classes[size]} ${className}`;

    if (switchFirst) {
        return (
            <div className={containerClass}>
                <Switch
                    disabled={disabled}
                    size={size}
                    color={color}
                    checked={value}
                    onChange={onChange}
                    className={switchClassName}
                />
                <div className={classes.switchFirstLabel}>{title}</div>
            </div>
        );
    }

    return (
        <div className={containerClass}>
            <div className={classes.switchLabel}>{title}</div>
            <Switch
                disabled={disabled}
                size={size}
                color={color}
                checked={value}
                onChange={onChange}
                className={switchClassName}
            />
        </div>
    );
};

export default SwitchWithLabel;
