import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => {
    return {
        tabTitles: {
            display: 'inline-flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '25px 0 10px 0',
            '@media (max-aspect-ratio: 9/17)': {
                marginTop: '60px',
                gap: '30px',
            },

        },
        title: {
            textAlign: 'center',
            margin: '0 25px 0 25px',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '13px',
            color: '#000000DE',
            lineHeight: 1.1,
        },
        mainTitle: {
            fontSize: 24,
            minHeight: 35,
        },
        subTitle: {
            fontSize: 18,
        },
    };
});

export default useStyles;
