import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            color: '#000000DE',
            gap: '30px',
            '@media (max-width:380px)': {
                gap: '15px',
            },
        },
        tabContent: {},
        topScreenWrapper: {
            display: 'flex',
            alignItems: 'flex-start',
        },
        topScreen: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            '@media (max-aspect-ratio: 9/17)': {
                marginTop: '24px',
            },
        },
        fullTab: {
            justifyContent: 'center',
            fontSize: '14px',
            padding: '14px 2px',
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            height: '100%',
        },
        containerText: {
            fontSize: '24px',
            color: theme.palette.secondary[600],
            fontFamily: 'Roboto',
            fontWeight: 400,

        },
        serialNumber: {
            fontSize: '56px',
            fontFamily: 'Roboto',
        },
        temperatureTextWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            '@media (max-aspect-ratio: 9/17)': {
                '& span:nth-child(3)': {
                    marginTop: '14px',
                },
            },
            '&>span:nth-child(3)': {
                color: theme.palette.secondary[800],
                lineHeight: '1.5',
            },
            '&>span:nth-child(2)': {
                color: theme.palette.secondary[800],
            },
            '&>span:nth-child(1)': {
                color: theme.palette.secondary[600],
            },

        },
        temperatureTitle: {
            fontSize: '24px',
            color: theme.palette.secondary[600],
            fontFamily: 'Roboto',
            fontWeight: 400,
        },
        temperature: {},
        cardHolder: {},
        measurementCardHolder: {
        },
        measurementCard: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 15,
            gap: 40,
            '&  *': {
                fontFamily: 'Roboto',
            },
            '& > div': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '5px',

            },

        },
        measuredTemperatureSwitch: {
            width: 'calc(100% - 13px) !important',
            maxWidth: 'calc(440px - 13px) !important',
        },
        buttonsContainer: {
            maxWidth: '400px',
            width: '100%',
            padding: '15px 25px 25px 25px',
            boxSizing: 'border-box',
        },
        tabsRow: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '800px',
            width: 'calc(100% - 54px)',
            marginTop: '20px',
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
            },
        },
        button: {
            width: 'auto',
            margin: '4px 3px',
            minWidth: 'unset',
            fontSize: '14px',
            padding: '14px',
        },
        buttonFullWidth: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
        },
        shipmentCardWrapper: {
            width: 'calc(100% - 54px)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        shimpentCardHolder: {
            marginTop: -20,
            width: '100%',
            maxWidth: 440,
            color: '#747474',
            '& svg': {
                fontSize: '16px',
            },
        },
        noSensorData: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '260px',
            '& .title': {
                marginBottom: '14px',
                color: theme.palette.primary.contrastText,
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '28px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
            },
            '& .description': {
                color: theme.palette.secondary[600],
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
            },
        },
        icon: {
            width: 16,
            height: 16,
            marginRight: 8,
        },
        shipmentCardContainer: {
            '& *': {
                font: 'normal normal normal 14px/17px Roboto',
            },
        },
        noMeasuredData: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            width: '350px',
            marginTop: '4%',
            '& .title': {
                marginBottom: '24px',
                color: theme.palette.primary.contrastText,
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '28px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                maxWidth: '280px',
            },
            '& .description': {
                color: theme.palette.secondary[600],
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                maxWidth: '280px',
            },
        },
        shipmentCardTitle: {
            display: 'flex',
            padding: '13px 13px 8px 13px',
            borderBottom: '1px solid #D7D7D7',
            color: '#747474',
            '& b': {
                marginRight: 5,
                fontFamily: 'Roboto',
            },
            '& *': {
                fontSize: '14px',
            },
            [theme.breakpoints.down('xs')]: {
                gridTemplateColumns: '1fr 1fr',
                paddingBottom: '13px',
            },
        },
        shipmentCardBody: {
            padding: '16px 13px 16px 13px',
            color: '#747474',
            display: 'grid',
            gridTemplateColumns: 'max-content 1fr',
            gridGap: '16px 30px',
            fontFamily: 'Roboto',
            '& *': {
                fontFamily: 'Roboto',
            },
            '& > span:nth-child(2n+1)': {
                textAlign: 'left',
            },
            '& > span:nth-child(2n)': {
                display: 'flex',
                textAlign: 'left',
                alignItems: 'center',
                justifyContent: 'flex-end',
                whiteSpace: 'pre-wrap',
                '& > div': {
                    justifyContent: 'flex-end',
                },
            },

        },
        minMaxRange: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            width: '100%',
        },
        containerOfflineImage: {
            width: '80%',
        },
        containerOfflineText: {
            width: '100%',
            textAlign: 'center',
            fontSize: 36,
        },
        green: {
            background: '#17921f',
        },
        orange: {
            background: '#edae49',
        },
        red: {
            background: '#D44848',
        },
        sleepingImg: {
            width: 'calc(100% - 50px)',
            maxWidth: '500px',
        },
        fontSize36: {
            fontSize: 36,
            fontFamily: 'Roboto',
        },
        fontSize96: {
            fontSize: 80,
            color: '#000000DE',
            fontFamily: 'Roboto',
        },
        spanTooltip: {
            cursor: 'pointer',
            transition: '200ms ease',
            '&:hover': {
                color: theme.palette.primary[300],
            },
        },
        dot: {
            width: '10px',
            minWidth: '10px',
            height: '10px',
            borderRadius: '50%',
        },
    };
});

export default useStyles;
