import React, { useCallback, useEffect, useState } from 'react';

import FieldTitle from 'app/components/FieldTitle';
import PhotoPicker from 'app/components/PhotoPicker';

import useAssetNumber from 'app/hooks/useAssetNumber';
import useSaveAttachment from 'app/hooks/useSaveAttachment';

import LoadingSkyCell from 'app/components/LoadingSkyCell';
import { useTranslation } from 'react-i18next';
import useStyles from './Picture.style';
import SavingError from '../../SavingError';
import AddComment from '../../AddComment';

type Props = {
    type: string,
    title: string,
    subTitle: string,
    label: string,
    skippable?: boolean,
    value: any,
    process: string,
    onChange: (...args: any) => any,
    onSubmit: (...args: any) => any,
}

const APP_NAME = 'SkyMind';

const Picture = ({
    type,
    title,
    subTitle,
    label,
    skippable = false,
    process,
    onChange,
    onSubmit,
    value = null,
}: Props) => {
    const [
        picture,
        setPicture,
    ] = useState(null);

    const classes = useStyles();
    const [assetNumber] = useAssetNumber();
    const [description, setDescription] = useState(null);
    const [commentScreen, setCommentScreen] = useState(false);
    const [commentSubmitted, setCommentSubmitted] = useState(false);

    const { t } = useTranslation();
    const {
        data: attachment,
        mutate: saveAttachment,
        isLoading,
        isSuccess,
        error: saveAttachmentError,
    } = useSaveAttachment();

    const handleChange = useCallback((input) => {
        setPicture(input.target.value);
    }, [setPicture]);

    const handleSubmitPicture = useCallback(() => {
        setCommentScreen(true);
    }, [
        setCommentScreen,
    ]);

    const handleSubmitComment = useCallback(() => {
        setCommentSubmitted(true);
    }, [
        attachment,
        description,
        label,
        isSuccess,
    ]);

    useEffect(() => {
        if (value?.picture?.id) {
            onSubmit();
        }
    }, [
        value,
        onSubmit,
    ]);
    useEffect(() => {
        if (isSuccess) {
            onChange({
                target: {
                    value: {
                        attachment,
                        description,
                    },
                },
            }, label);
        }
    }, [
        isSuccess,
        attachment,
        description,
    ]);

    useEffect(() => {
        if (commentSubmitted && picture) {
            saveAttachment({
                file: picture,
                fileName: `${APP_NAME}-${process}-AssetNb_${assetNumber}-${type}-${label}.png`,
                description,
            });
        }
    }, [
        picture,
        process,
        assetNumber,
        type,
        label,
        description,
        commentSubmitted,
    ]);

    if (isLoading) {
        return <LoadingSkyCell />;
    }
    if (saveAttachmentError) {
        return (
            <SavingError
                title="CANT_SAVE_PICTURES"
                onTryAgain={handleSubmitPicture}
            />
        );
    }

    if (commentScreen) {
        return (
            <>
                <AddComment
                    onNext={handleSubmitComment}
                    onTextChanged={setDescription}
                    text={description}
                />
            </>
        );
    }

    return (
        <>
            <FieldTitle
                title={picture ? t('REVIEW_PICTURE') : title}
                subTitle={subTitle}
            />

            <div
                className={classes.tabContent}
            >
                <PhotoPicker
                    label={label}
                    value={picture}
                    skippable={skippable}
                    onChange={handleChange}
                    onSubmit={handleSubmitPicture}
                    onSkip={skippable ? onSubmit : null}
                />
            </div>

        </>
    );
};

export default Picture;
