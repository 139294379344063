import { ExtendedProfile, UserInfo } from './dataTypes';

export const initialExtendedProfile: ExtendedProfile = {
    assignedRoles: [],
    username: '',
    name: '',
    companyId: null,
    firstName: '',
    lastName: '',
    email: '',
    emailVerified: null,
};

export const initialUserInfo: UserInfo = {
    assignedRoles: [],
    avatar: '',
    companyId: null,
    id: null,
    companyName: null,
    name: '',
    email: '',
};

export const getAvatar = (gender: string) => {
    return gender || '';
};

export const getUserInfo = (extendedProfile: ExtendedProfile, avatar = ''): UserInfo => {
    const {
        assignedRoles = [],
        email,
        name,
        companyId,
    } = extendedProfile;

    return {
        avatar,
        assignedRoles,
        companyId,
        email,
        name,
    };
};
