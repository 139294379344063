import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { TempRangeStatus } from 'app/utils/dataTypes';
import useStyles from '../TemperatureInfo.style';
import { dateTimeMaskUTC } from '../../../../utils/constants';
import TemperatureCheckIcon from '../../../../shared-components/TemperatureCheckIcon';

type Props = {
    tempRangeCheck?: TempRangeStatus,
    lastMeasuredTempInternal?: number,
    lastMeasuredTempInternalTimestamp?: string
}

const MeasurementCard = ({
    tempRangeCheck = TempRangeStatus.NO_DATA,
    lastMeasuredTempInternal = null,
    lastMeasuredTempInternalTimestamp = null,
}: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [minutesDiff, setMinutesDiff] = useState<number>(null);

    const lastMeasuredAgo = useMemo(() => {
        if (!lastMeasuredTempInternalTimestamp) return t('TEMPERATURE.NOT_MEASURED_YET');

        const duration = moment
            .duration(moment().utc(false)
                .diff(moment(lastMeasuredTempInternalTimestamp, dateTimeMaskUTC).utc(true)));
        const minutes = Math.ceil(Math.abs(duration.asMinutes()));
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        setMinutesDiff(minutes);

        const daysS = days !== 1 ? 'TEMPERATURE.DAYS' : 'TEMPERATURE.DAY';
        const hoursS = hours % 24 !== 1 ? 'TEMPERATURE.HOURS' : 'TEMPERATURE.HOUR';
        const minutesS = minutes % 60 !== 1 ? 'TEMPERATURE.MINUTES' : 'TEMPERATURE.MINUTE';

        return (days ? `${days} ${t(daysS)} ` : '')
            + (hours ? `${hours % 24} ${t(hoursS)} ` : '')
            + (minutes ? `${minutes % 60} ${t(minutesS)} ` : '')
            + t('TEMPERATURE.AGO');
    }, [lastMeasuredTempInternalTimestamp]);

    const lastMeasuredAgoColor = useMemo(() => {
        if (!minutesDiff || minutesDiff >= 24 * 60) return classes.red;
        if (minutesDiff >= 60 && minutesDiff < 24 * 60) return classes.orange;
        if (minutesDiff < 60) return classes.green;
        return '';
    }, [minutesDiff]);

    const lastMeasuredAgoText = useMemo(() => {
        return lastMeasuredTempInternal.toFixed(1)
            ? <span className={classes.fontSize96}>{`${lastMeasuredTempInternal.toFixed(1)}°C`}</span>
            : <span className={classes.fontSize36}>{t('NOT_ASSIGNED')}</span>;
    }, [lastMeasuredTempInternalTimestamp]);

    if (!lastMeasuredTempInternal || !lastMeasuredTempInternal || !tempRangeCheck) {
        return (
            <div style={{ height: 215 }}>
                <div className={classes.cardHolder}>
                    <div className={classes.temperatureTextWrapper}>
                        <span className={classes.temperatureTitle}>{t('TEMPERATURE.INTERNAL_TEMPERATURE')}</span>
                        <span style={{
                            fontSize: 36,
                        }}
                        >
                            No Data
                        </span>
                        <span style={{
                            textAlign: 'center',
                            display: 'flex',
                            padding: '0px 30px',
                        }}
                        >
                            No temperature has been received for this packaging at this point in time.
                        </span>

                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className={classes.cardHolder}>
                <div className={classes.temperatureTextWrapper}>
                    <span className={classes.temperatureTitle}>{t('TEMPERATURE.INTERNAL_TEMPERATURE')}</span>
                    <span className={classes.temperature}>
                        {lastMeasuredAgoText}
                    </span>
                </div>

                <div className={classes.measurementCardHolder}>
                    <div className={classes.measurementCard}>
                        <div>
                            <span
                                className={[
                                    lastMeasuredAgoColor,
                                    classes.dot,
                                ].join(' ')}
                            />
                            <span>
                                Measured
                                {' '}
                                {lastMeasuredAgo}
                            </span>
                        </div>
                        <div>
                            <TemperatureCheckIcon
                                temperatureCheckStatus={tempRangeCheck}
                                size="16px"
                                minimalistic
                            />
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default MeasurementCard;
