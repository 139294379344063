import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useStyles
from 'app/components/ErrorScreenMultipurpose/ErrorScreenMultipurpose.style';
import Button from '../Button';

export type ErrorMessage = {
    errorTitle?: string,
    errorMessage?: string,
    serialNumber?: string,
    icon?: 'no_search' | 'caution' | 'lock',
}
type Props = {
    error?: ErrorMessage,
    onRepeat?: any,
}

const icons = {
    no_search: 'https://assets.dev.skymind.com/assets/images/icons/nothing_found.svg',
    caution: 'https://assets.dev.skymind.com/assets/images/icons/cation_triangle.svg',
    lock: 'https://assets.dev.skymind.com/assets/images/icons/lock_skyship.svg',
};
const ErrorScreenMultipurpose = ({
    error = null,
    onRepeat = null,
}: Props) => {
    const classes = useStyles();
    const {
        t,
    } = useTranslation();

    const history = useHistory();

    return (
        <div
            className={classes.container}
        >

            <div
                className={classes.header}
            >
                <img
                    className={classes.img}
                    src={icons[error?.icon || 'caution']}
                    alt={error?.icon || 'caution'}
                />

                {
                    error?.serialNumber && (
                        <div
                            className={classes.numberTitle}
                        >
                            {error?.serialNumber}
                        </div>
                    )
                }
                <div
                    className={classes.title}
                >
                    {error?.errorTitle || t('ERROR.SOMETHING_WRONG_TITLE')}
                </div>
                <div
                    className={classes.description}
                >
                    {error?.errorMessage || t('ERROR.SOMETHING_WRONG_DESC')}
                </div>
                <div style={{
                    height: '120px',
                }}
                />

            </div>

            {
                onRepeat && error.icon !== 'lock' && (
                    <Button
                        fullWidth
                        classNames={classes.btn}
                        onClick={() => history.push('/')}
                        text={t('TRY_AGAIN')}
                    />
                )
            }

        </div>
    );
};

export default ErrorScreenMultipurpose;
