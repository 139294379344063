import ErrorScreenMultipurpose from 'app/components/ErrorScreenMultipurpose';
import {
    ErrorMessage,
} from 'app/components/ErrorScreenMultipurpose/ErrorScreenMultipurpose';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Order } from 'app/utils/dataTypes';
import LoadingSkyCell from 'app/components/LoadingSkyCell';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import { dateTimeMaskTimestamp } from 'app/utils/constants';
import useUserRoles from 'app/hooks/useUserRoles';
import useSecureBackendEndpoints from 'app/hooks/useSecureBackendEndpoints';
import { SensorDataResponse } from 'app/dataTypes/SecureBackend/apiResponse/SensorData';
import { AxiosResponse } from 'axios';
import { encodeObjToQuery } from 'app/utils/tools';
import TemperatureInfoContents from './TemperatureInfoContents';
import useStyles from './TemperatureInfo.style';
import Button from '../../Button';
import SkyMindButton from '../../SkyMindButton';
import { TemperatureReadout, TemperatureReadoutData } from './TemperatureInfoContents/TemperatureReadout';

type Props = {
    process: string,
    title?: string,
    value?: {
        serialNumber: string
    },
    subTitle?: string,
    label: string,
    skippable?: boolean,
    onChange: (...arg: any) => any,
    onSubmit: (...arg: any) => any,
    onReturn: (...arg: any) => any
}
const Buttons = {
    NOW: 'TEMPERATURE.CURRENT_TEMPERATURE',
    SHIPMENT: 'TEMPERATURE.SHIPMENT_SUMMARY',
};

const TemperatureInfo = ({
    onReturn,
    value,
}: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [buttonPressed, setButtonPressed] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [isReadoutScreen, setIsReadoutScreen] = useState<boolean>(false);
    const [temperatureReadoutData, setTemperatureReadoutData] = useState<TemperatureReadoutData>(null);
    const [cargo, setCargo] = useState<any>(null);
    const [currentOrder, setCurrentOrder] = useState<Order>();
    const [sensorDataLoading, setSensorDataLoading] = useState(false);
    const roles = useUserRoles();
    const [shipmentId, setShipmentId] = useState(null);
    const [cargoId, setCargoId] = useState(null);
    const serialNumber = value?.serialNumber;
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState<ErrorMessage>(null);
    const [containerError, setContainerError] = useState(false);
    const [splitMeasurementsAndPredictions, setSplitMeasurementsAndPredictions] = useState(true);
    const {
        FlexibleRequest: secureRequest,
    } = useSecureBackendEndpoints('').requests;

    useEffect(() => {
        if (shipmentId) {
            (async () => {
                setLoading(true);
                const result = await secureRequest('GET', `v2/shipments/${shipmentId}`);

                if (result?.data) setCurrentOrder(result?.data || null);
                setLoading(false);
            }
            )();
        }
    }, [shipmentId]);

    const checkTemperatureReadout = async () => {
        try {
            const temperatureReadoutRes: AxiosResponse<SensorDataResponse> = await secureRequest(
                'GET',
                `/packagings/${serialNumber}/sensor-data?${encodeObjToQuery({
                    splitMeasurementsAndPredictions,
                })}`,
            );

            if (!temperatureReadoutRes.data) {
                setErrorMessage({
                    errorTitle: t('ERROR.NO_PACKAGING'),
                    errorMessage: t('ERROR.NO_PACKAGING_DESC'),
                    icon: 'no_search',
                    serialNumber,
                });
                return;
            }
            if (temperatureReadoutRes?.data?.data?.length === 0) {
                setTemperatureReadoutData({
                    notFound: true,
                    sensorDataResponse: null,
                });
                setIsReadoutScreen(true);
                return;
            }
            setTemperatureReadoutData({
                notFound: false,
                sensorDataResponse: temperatureReadoutRes.data,
            });
            setIsReadoutScreen(true);
        } catch (error) {
            if (error?.response?.status === 403) {
                setErrorMessage({
                    errorTitle: t('ERROR.ACCESS_DENIED'),
                    errorMessage: t('ERROR.ACCESS_DENIED_DESC'),
                    icon: 'lock',
                });
                return;
            }
            if (error?.response?.status === 400) {
                setIsReadoutScreen(true);
                if (error?.response?.data?.errorMessage.includes('Error get Sensor Data for packaging')) {
                    // means: Packaging found, but no sensor data for last 24 hours
                    setTemperatureReadoutData({
                        notFound: false,
                        sensorDataResponse: null,
                    });
                    setErrorMessage({
                        errorTitle: t('ERROR.NO_PACKAGING'),
                        errorMessage: t('ERROR.NO_PACKAGING_DESC'),
                        icon: 'no_search',
                        serialNumber,
                    });
                } else if (error?.response?.data?.errorMessage.includes('not found')) {
                    setErrorMessage({
                        errorTitle: t('ERROR.NO_PACKAGING'),
                        errorMessage: t('ERROR.NO_PACKAGING_DESC'),
                        icon: 'no_search',
                        serialNumber,
                    });
                }
                return;
            }
            setErrorMessage({
                errorTitle: t('ERROR.SOMETHING_WRONG_TITLE'),
                errorMessage: t('ERROR.SOMETHING_WRONG_DESC'),
                icon: 'caution',
            });
        }
    };

    useEffect(() => {
        (
            async () => {
                try {
                    const res = await secureRequest(
                        'GET',
                            `v2/shipments/search?page=0&pageSize=1000&q=${serialNumber}`,
                    );
                    const shipments = res?.data?.resultList;

                    if (!shipments || shipments.length === 0) {
                        await checkTemperatureReadout();

                        setLoading(false);
                        return;
                    }
                    const [latestShipment = null] = shipments
                        .filter(s => (s.status === 'TRANSPORT' || s.status === 'IN_TRANSIT')
                                && s.cargo.some(c => c.packaging.serialNumber === serialNumber))
                        .sort((a, b) => moment(b.shipmentStart).valueOf()
                            - moment(a.shipmentStart).valueOf());

                    setShipmentId(latestShipment?.id);
                    const cargo = latestShipment
                        ?.cargo
                        ?.find(cargo => cargo.packaging.serialNumber === serialNumber);

                    if (!cargo) {
                        await checkTemperatureReadout();

                        setLoading(false);
                        return;
                    }
                    setCargo(cargo);
                    setCargoId(cargo.id);
                    setLoading(false);
                } catch (e) {
                    if (e?.response?.status === 500) {
                        setErrorMessage({
                            errorTitle: t('ERROR.SOMETHING_WRONG_TITLE'),
                            errorMessage: t('ERROR.SOMETHING_WRONG_DESC'),
                            icon: 'caution',
                        });
                    }

                    const errorMessage = t(
                        e?.response?.status !== 500 && e?.response?.data?.errorMessage
                            ? e?.response?.data?.errorMessage
                            : 'ERROR.SOMETHING_WRONG_DESC',
                    );

                    setErrorMessage({
                        errorTitle: t('ERROR.SOMETHING_WRONG_TITLE'),
                        errorMessage,
                        icon: 'caution',
                    });

                    setLoading(false);
                }
            }
        )();
    }, [serialNumber]);

    useEffect(() => {
        if (!isReadoutScreen) return;
        checkTemperatureReadout();
    }, [splitMeasurementsAndPredictions]);

    const expectedEnd = useMemo(() => {
        return (!currentOrder?.shipmentEnd) ? moment(
            Math.max(
                moment(currentOrder?.shipmentEndExpected, dateTimeMaskTimestamp)
                    .utc(false)
                    .valueOf(),
                moment()
                    .add({
                        hour: new Date().getTimezoneOffset() / 60,
                        minute: new Date().getTimezoneOffset() % 60,
                    })
                    .valueOf(),
            ),
        ).utc(true) : null;
    }, [currentOrder]);

    const currentButtons = useMemo<any[]>(() => {
        const buttonsArray = [
            'NOW',
        ];

        if (currentOrder?.shipmentStart) {
            buttonsArray.push('SHIPMENT');
        }
        setButtonPressed('NOW');

        return buttonsArray;
    }, [currentOrder, roles]);

    const handleSplitMeasurementsAndPredictions = useCallback(() => {
        setSplitMeasurementsAndPredictions((prev) => !prev);
    }, []);

    if (errorMessage || containerError) {
        return (
            <ErrorScreenMultipurpose
                error={errorMessage}
                onRepeat={() => {
                    history.push('/');
                    setImmediate(() => {
                        history.push('/CHECK_TEMPERATURE');
                    });
                }}
            />
        );
    }

    if (loading) {
        return (
            <>
                <div style={{ height: '100%' }} />
                <LoadingSkyCell />
            </>
        );
    }

    if (isReadoutScreen) {
        return (
            <div className={classes.container} style={{ marginTop: 48 }}>
                <TemperatureReadout
                    notFound={temperatureReadoutData?.notFound}
                    sensorDataResponse={temperatureReadoutData?.sensorDataResponse}
                    serialNumber={serialNumber}
                    splitMeasurementsAndPredictions={splitMeasurementsAndPredictions}
                    onChangeSplitMeasurementsAndPredictions={handleSplitMeasurementsAndPredictions}
                />

                <div className={classes.buttonsContainer}>
                    <SkyMindButton
                        text={t('SCAN_OTHER_CONTAINER')}
                        onClick={onReturn}
                        fullWidth
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={classes.container}>
            <div className={classes.tabsRow}>
                {
                    currentButtons.length > 1 && currentButtons.map(button => {
                        return (
                            <Button
                                key={`button_in_row_${button}`}
                                classNames={classes.fullTab}
                                text={t(Buttons[button])}
                                upperCase={false}
                                loading={sensorDataLoading}
                                onClick={() => setButtonPressed(button)}
                                active={buttonPressed === button}
                                tab
                            />
                        );
                    })
                }
            </div>

            {
                !loading && (
                    <TemperatureInfoContents
                        serialNumber={serialNumber}
                        order={currentOrder}
                        cargo={cargo}
                        shipmentId={shipmentId}
                        cargoId={cargoId}
                        buttonPressed={buttonPressed}
                        setSensorDataLoading={setSensorDataLoading}
                        setErrorMessage={setErrorMessage}
                        sensorDataLoading={sensorDataLoading}
                        expectedLease={expectedEnd}
                        setContainerError={setContainerError}
                    />
                )
            }

            <div className={classes.buttonsContainer}>
                <SkyMindButton
                    text={t('SCAN_OTHER_CONTAINER')}
                    onClick={onReturn}
                    fullWidth
                />
            </div>

        </div>
    );
};

export default TemperatureInfo;
