import React, { CSSProperties, useMemo } from 'react';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TEMPERATURE_RANGES_OPTIONS, TEMPERATURE_RANGES_VALUES } from '../constants';

type Mode = 'full' | 'reduced';

const getRangeValues = (tempRange: string, mode: Mode, toSeparator: string): string => {
    const { tempRangeMin, tempRangeMax } = TEMPERATURE_RANGES_VALUES[tempRange] || {};
    const min = mode === 'reduced' ? tempRangeMin?.toString() : `${tempRangeMin}°C`;
    const max = mode === 'reduced' ? tempRangeMax?.toString() : `${tempRangeMax}°C`;

    if (tempRange === TEMPERATURE_RANGES_OPTIONS.F || tempRange === TEMPERATURE_RANGES_OPTIONS.DF) {
        return `(${min} ${toSeparator} ${max})`;
    }
    return `(${min} - ${max})`;
};

const containerStyle: CSSProperties = {
    alignItems: 'center',
    display: 'flex',
    gap: '5px',
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Roboto',
    letterSpacing: '0.18px',
    opacity: 1,
    whiteSpace: 'nowrap',
    // color: '#000000', // it is black in the specification, but it makes sense to get a common style in some places
};

// todo: I guess it makes sense to specify tempRange as type 'CRT' | 'C' | 'F' | 'DF' (in the whole project)
type Props = {
    mode?: Mode,
    circle?: boolean,
    tempRange: string,
    size?: string | number
}

const TempRange = ({
    mode = 'full',
    circle = false,
    tempRange,
    size = 14,
} : Props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const rangeKey = TEMPERATURE_RANGES_OPTIONS[tempRange];
    const rangeValues = getRangeValues(tempRange, mode, t('TO').toLowerCase());
    const rangeKeyColor = tempRange === TEMPERATURE_RANGES_OPTIONS.CRT
        ? theme.palette.common['yellow'] : theme.palette.primary['deepBlue'];

    const dotStyle = useMemo(() => ({
        background: rangeKeyColor,
        width: 15,
        height: 15,
        borderRadius: '50%',
    }),
    [rangeKeyColor]);
    const temperature = useMemo(() => {
        if (!Object.keys(TEMPERATURE_RANGES_OPTIONS).includes(tempRange)) {
            return <div>{tempRange}</div>;
        }

        return (
            <div style={{ fontSize: size, ...containerStyle }}>
                {
                    circle && (
                        <div style={dotStyle} />
                    )
                }
                <div style={{ color: rangeKeyColor, fontSize: size }}>{rangeKey}</div>
                <div style={{ color: theme.palette.secondary[600], fontSize: size }}>{rangeValues}</div>
            </div>
        );
    }, [rangeKey, rangeValues, tempRange]);

    return (
        temperature
    );
};

export default TempRange;
