/* eslint-disable no-unused-vars */
import LoadingSkyCell from 'app/components/LoadingSkyCell';
import React, { createContext, ReactChild, useEffect, useMemo, useState } from 'react';

import loadScript from 'app/utils/loadScript';
import StatusInfo from 'app/shared-components/StatusInfo';
import useAbstractService from 'app/hooks/useAbstractService';
import Keycloak from 'keycloak-js';
import { AuthContextInterface, ExtendedProfile, KeycloakProfile, UserInfo } from './dataTypes';

import { getUserInfo, initialExtendedProfile, initialUserInfo } from './lib';

const {
    REACT_APP_AUTH_SERVER_URL: url,
    REACT_APP_REALM: realm,
    REACT_APP_RESOURCE: clientId,
} = process.env;

const TIME_IN_MS = {
    second: 1000,
    minute: 60 * 1000,
    hour: 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
};

type Props = {
    children: ReactChild,
}

export const AuthContext = createContext<AuthContextInterface>({
    userInfo: initialUserInfo,
    token: '',
    logout: () => {},
});
const keycloakLink = `${url}/js/keycloak.js`;

const Auth = ({ children } : Props) => {
    const { makeExternalRequest: getGender } = useAbstractService({ method: 'GET' });
    const [avatar, setAvatar] = useState<string>('');
    const [token, setToken] = useState<string>('');
    const [timeOutId, setTimeOutId] = useState(null);
    const [keycloak, setKeycloak] = useState<Keycloak>(null);
    const [status, setStatus] = useState<string>('INIT');
    const [extendedProfile, setExtendedProfile] = useState<ExtendedProfile>(initialExtendedProfile);

    useEffect(() => {
        (async () => {
            try {
                setStatus('PENDING');
                await loadScript(keycloakLink);

                // @ts-ignore
                const keycloak: Keycloak = new window.Keycloak({
                    url,
                    realm,
                    clientId,
                });

                if (keycloak) {
                    await keycloak.init({
                        onLoad: 'login-required',
                    });

                    setKeycloak(keycloak);
                    const { token } = keycloak;

                    setToken(token);
                    setStatus('SUCCESS');
                } else {
                    setStatus('FAILURE');
                }
            } catch (error) {
                global.console.log(error);
                setStatus('FAILURE');
            }
        })();
    }, []);

    useEffect(() => {
        if (keycloak) {
            const { tokenParsed } = keycloak;
            const { exp } = tokenParsed;
            const timeout = exp * TIME_IN_MS.second - Date.now();

            if (timeOutId) {
                clearTimeout(timeOutId);
            }

            const newTimeOutId = setTimeout(() => {
                keycloak.updateToken(TIME_IN_MS.minute * 5)
                    .then((refreshed) => {
                        if (refreshed) {
                            const { token } = keycloak;

                            setToken(token);
                        }
                    })
                    .catch((err) => {
                        global.console.log(err);
                        keycloak.logout();
                    });
            }, timeout);

            setTimeOutId(newTimeOutId);
        }
    }, [keycloak, token]);

    useEffect(() => {
        if (keycloak && !extendedProfile.email) {
            (async () => {
                try {
                    const profile: KeycloakProfile = await keycloak.loadUserProfile();
                    const { tokenParsed } = keycloak;
                    const { groups: assignedRoles = [], name } = tokenParsed;

                    setExtendedProfile({
                        ...profile,
                        assignedRoles,
                        name,
                    });
                } catch (error) {
                    setExtendedProfile(initialExtendedProfile);
                }
            })();
        }
    }, [keycloak]);

    const userInfo: UserInfo = useMemo(() => {
        if (!extendedProfile.email) {
            return initialUserInfo;
        }

        return getUserInfo(extendedProfile, avatar);
    }, [extendedProfile, avatar]);

    return (
        status === 'SUCCESS'
            ? (
                <AuthContext.Provider
                    value={{
                        userInfo,
                        token,
                        logout: keycloak ? keycloak.logout : () => {},
                    }}
                >
                    {children}
                </AuthContext.Provider>
            )
            : (
                <StatusInfo
                    status={status}
                    error="An Error happened during login"
                />
            )
    );
};

export default Auth;
