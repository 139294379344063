import AppDrawerContext from 'app/context/AppDrawerContext';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ReactNodeLike } from 'prop-types';
import { SwipeableDrawer, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useUserRoles from 'app/hooks/useUserRoles';
import { useHistory } from 'react-router-dom';
import useStyles from '../components/Header/Header.style';
import { AppMenuItem, AppMenuItemType, Process } from '../utils/dataTypes';
import useFetchProcesses from '../hooks/useFetchProcesses';
import { SSI_PROCESS_EXECUTION } from '../screens/MainMenu/MainMenu';
import { AuthContext } from '../context/AuthContext';
import useSecureBackendEndpoints from '../hooks/useSecureBackendEndpoints';

type Props = {
    children: ReactNodeLike
}

const processIconMap = (process) => ({
    CHECK_TEMPERATURE: 'icons/magnify.svg',
    RECEIVING: 'icons/checkboard.svg',
    SENDING: 'icons/truck_loading.svg',
})[process];

const convertProcessToMenuItem: any = (process: Process, t) => {
    return {
        label: t(`${process.processLabel}`),
        type: AppMenuItemType.ROUTE,
        route: `/${process.process}`,
        icon: processIconMap(process.process),
    };
};
const Line = (
    <hr style={{
        border: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        width: '100%',
        height: 1,
        margin: 0,
    }}
    />
);

const DrawBarProvider = ({ children = [] }: Props) => {
    const [open, setOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { userInfo, logout } = useContext(AuthContext);
    const [userDetails, setUserDetails] = useState<any>(null);
    const [companyName, setCompanyName] = useState<any>(null);
    const { Get: getUserDetails } = useSecureBackendEndpoints('companies').requests;
    const roles = useUserRoles();
    const history = useHistory();

    const hasRoles = useMemo(() => {
        return roles.includes(SSI_PROCESS_EXECUTION);
    }, [roles]);

    const {
        processes,
    } = useFetchProcesses(hasRoles);

    useEffect(() => {
        if (userInfo.companyId && !userDetails) {
            (async () => {
                try {
                    const result = await getUserDetails(userInfo.companyId);

                    if (result.data) {
                        setUserDetails(result.data);
                        setCompanyName(result.data.name);
                    } else {
                        global.console.log('No data received from API');
                    }
                } catch (error) {
                    global.console.error('Error fetching user details:', error);
                }
            })();
        }
    }, [userInfo.email, userInfo.companyId]);

    const processItems = useMemo<AppMenuItem[]>(() => {
        const children = processes?.map(process => convertProcessToMenuItem(process, t)) || [];

        if (children.length === 0) return null;
        return children;
    }, [i18n.language, processes]);

    const navigateTo = useCallback((route: string) => {
        setOpen(false);
        setTimeout(() => history.push('/'), 0);
        setTimeout(() => history.push(route), 2);
    }, [history]);

    return (
        <AppDrawerContext.Provider
            value={{
                open,
                setOpen,
            }}
        >
            <SwipeableDrawer
                anchor="left"
                classes={{ paper: classes.paper }}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            >
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
                >
                    <div
                        onClick={() => setOpen(false)}
                        style={{
                            backgroundColor: theme.palette.secondary[50],
                            color: 'white',
                            borderRadius: '50%',
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <span style={{
                            position: 'absolute',
                            transform: 'rotate(45deg)',
                            height: '13px',
                            width: '1px',
                            backgroundColor: '#3a3a3c',
                        }}
                        />
                        <span style={{
                            position: 'absolute',
                            transform: 'rotate(-45deg)',
                            height: '13px',
                            width: '1px',
                            backgroundColor: '#3a3a3c',
                        }}
                        />
                    </div>

                </div>
                <div style={{ height: 30 }} />
                <div style={{
                    fontSize: 20,
                    fontWeight: 400,
                    fontFamily: 'Roboto Medium',
                    color: theme.palette.common.black,
                    marginBottom: 10,
                }}
                >
                    {userInfo?.name}
                </div>
                <div style={{
                    fontSize: 16,
                    fontFamily: 'Roboto',
                    fontWeight: 400,
                    marginBottom: 47,
                    color: theme.palette.common.black,
                }}
                >
                    {companyName || t('NO_COMPANY')}
                </div>
                {Line}
                {
                    processItems && processItems.map((item) => (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontFamily: 'Roboto Medium',
                                    justifyContent: 'center',
                                    width: '100%',
                                    padding: '26px 0',
                                    textTransform: 'uppercase',
                                    fontSize: 14,
                                    color: theme.palette.common.black,
                                }}
                                onClick={() => navigateTo(item.route)}
                            >
                                {item.label}
                            </div>
                            {Line}
                        </>
                    ))
                }

                <div style={{ flex: 1 }} />
                <div style={{
                    fontSize: 16,
                    fontFamily: 'Roboto Medium',
                    color: theme.palette.common.black,
                    display: 'flex',
                    paddingBottom: 27,
                    gap: 5,
                    flexDirection: 'column',
                }}
                >
                    <span style={{
                        letterSpacing: '0.15px',
                    }}
                    >
                        Contact support:
                    </span>
                    <a
                        href="mailto:support@skycell.ch"
                        style={{
                            color: theme.palette.primary['pastelBlue'],
                            fontWeight: 500,
                            fontSize: 16,
                            fontFamily: 'Roboto',
                            textDecoration: 'none',
                            letterSpacing: '0.15px',

                        }}
                    >
                        support@skycell.ch
                    </a>
                </div>
                {Line}
                <div>
                    <button
                        type="button"
                        onClick={logout}
                        className={classes.logoutButton}
                    >
                        {t('LOGOUT')}
                    </button>
                </div>
            </SwipeableDrawer>

            {children}
        </AppDrawerContext.Provider>
    );
};

export default DrawBarProvider;
