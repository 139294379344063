import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
    return {
        btn: {
            minWidth: 90,
            minHeight: 36,
            backgroundColor: theme.palette.primary['deepBlue'],
            fontFamily: 'Roboto Medium!important',
            fontSize: 14,
            fontWeight: 400,
            letterSpacing: '1.25px',
            color: theme.palette.common.white,
            textAlign: 'center',
            border: 'none',
            borderRadius: 8,
            padding: '12px 16px 12px 16px',
            boxShadow: '0px 1px 3px #00000033',
            margin: '30px 20px 30px 20px',
            transition: '200ms ease',
            maxWidth: '800px',
            '&[disabled]': {
                opacity: 0.5,
            },
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& > $progress': {
                color: theme.palette.common.white,
            },

        },
        tab: {
            background: 'none',
            color: theme.palette.secondary[500],
            border: 'none',
            fontFamily: 'Roboto',
            position: 'relative',
            '& span': {
                fontFamily: 'Roboto',
                fontWeight: 400,
            },
            '&::after': {
                content: '""',
                width: '100%',
                height: '1px',
                display: 'block',
                position: 'absolute',
                bottom: 0,
                backgroundColor: theme.palette.secondary[300],
            },
        },
        active: {
            color: theme.palette.primary['darkBlue'],
            '&::after': {
                content: '""',
                width: '100%',
                height: '3px',
                display: 'block',
                position: 'absolute',
                bottom: '-1px',
                backgroundColor: theme.palette.primary['darkBlue'],
            },

        },
        ghost: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary['deepBlue'],
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: theme.palette.primary['deepBlue'],
            '& > $progress': {
                color: theme.palette.primary.main,
            },
        },
        upperCase: {
            textTransform: 'uppercase',
        },
        progress: {
            display: 'flex',
            width: '16px!important',
            height: '16px!important',
            marginRight: 5,
            position: 'absolute',
        },
        loadingText: {
            filter: 'blur(0.5px)',
        },
        text: {
            display: 'flex',
            transition: '100ms ease',
            fontFamily: 'Roboto',

        },
    };
});

export default useStyles;
