import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';
import { TempRangeStatus } from '../../../../../utils/dataTypes';
import TemperatureCheckIcon from '../../../../../shared-components/TemperatureCheckIcon';

type Props = {
    temperatureStatus: TempRangeStatus,
    bold?: boolean,
}

const RangeCheckResult = ({
    temperatureStatus,
    bold = false,
}: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <>
            <span>{t('TEMPERATURE.RANGE_CHECK')}</span>
            <span style={{
                fontWeight: bold ? 'bold' : 'unset',
                color: bold ? theme.palette.common.black : 'unset',
            }}
            >
                <TemperatureCheckIcon
                    temperatureCheckStatus={temperatureStatus}
                    size="16px"
                />
            </span>
        </>
    );
};

export default RangeCheckResult;
