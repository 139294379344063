import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => {
    return {
        cameraControls: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 30,
            width: '100%',
            position: 'relative',
        },
        wrapper: {
            height: '100%',
            display: 'flex',
            paddingTop: '40px',
        },
    };
});

export default useStyles;
