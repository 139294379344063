import { Process } from '../../utils/dataTypes';
import { ROLES } from '../../utils/constants';

export const staticProcesses: Process[] = [
    {
        process: 'CHECK_TEMPERATURE',
        processLabel: 'PROCESS_NAME_CHECK_TEMPERATURE',
        processName: 'CHECK_TEMPERATURE_GENERAL',
        progressGroups: [
            {
                progressGroup: 1,
                steps: [
                    {
                        includeInReport: false,
                        isCommentable: false,
                        isNestedStep: true,
                        isRequired: false,
                        stepName: 'WELCOME_SCREEN',
                        stepTitle: 'WELCOME_SCREEN',
                        stepType: 'WELCOME_SCREEN',
                    },
                    {
                        includeInReport: false,
                        isCommentable: false,
                        isNestedStep: true,
                        isRequired: true,
                        stepName: 'SCAN_CONTAINER_CODE_RAW',
                        stepTitle: 'SCAN_CONTAINER_CODE',
                        stepType: 'CONTAINER_SERIAL_NUMBER_RAW',
                    },
                    {
                        includeInReport: false,
                        isCommentable: false,
                        isNestedStep: true,
                        isRequired: false,
                        stepName: 'CHECK_TEMPERATURE',
                        stepTitle: 'CHECK_TEMPERATURE',
                        stepType: 'CHECK_TEMPERATURE',
                    },
                ],
            },
        ],
    },
];

export const accessibleStaticProcesses: (roles: string[]) => Process[] = (roles) => {
    return staticProcesses.filter(process => {
        if (process.process === 'CHECK_TEMPERATURE') {
            // return Object.values(TEMPERATURE_RIGHTS).some((tempRole) => roles.includes(tempRole));
            return roles.includes(ROLES.SKYSHIP_EVIDENCE_COLLECTION)
                || roles.includes(ROLES.SKYSHIP_TEMPERATURE_READOUT);
        }
        return true;
    });
};
