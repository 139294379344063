import moment from 'moment';

export const lerpX = (p1, p2, intersectionY) => {
    const [x1, y1] = p1;
    const [x2, y2] = p2;

    if (y2 === y1) return x1;
    return Math.round(x1 + ((intersectionY - y1) / (y2 - y1)) * (x2 - x1));
};
export const TIME_IN_MS = {
    second: 1000,
    minute: 60 * 1000,
    hour: 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
};
const getUtcMoment = (dateTimeUtc?: string | number): moment.Moment => {
    return moment.utc(dateTimeUtc);
};

const getUtcToLocalMoment = (dateTimeUtc: string | number): moment.Moment => {
    return getUtcMoment(dateTimeUtc).local();
};

export const dateToNumber = (dateTimeUtc: string | number, inLocalTimeZone = false): number => {
    if (inLocalTimeZone) {
        return getUtcToLocalMoment(dateTimeUtc).valueOf();
    }
    return getUtcMoment(dateTimeUtc).valueOf();
};

export const interpolateExtremums = ({
    data,
    tempIndex,
    extremumTemp,
    inLocalTimeZone,
    rangeChecker,
    temperatureData,
}) => {
    return data.reduce((accumulator, { d, t, i }) => {
        const curX = dateToNumber(t, inLocalTimeZone);
        const prevX = dateToNumber(temperatureData?.[i - 1]?.t, inLocalTimeZone);
        const nextX = dateToNumber(temperatureData?.[i + 1]?.t, inLocalTimeZone);
        const curY = d[tempIndex];
        const prevY = temperatureData?.[i - 1]?.d[tempIndex];
        const nextY = temperatureData?.[i + 1]?.d[tempIndex];
        const prevExcursion = rangeChecker(prevY);
        const nextExcursion = rangeChecker(nextY);

        if (prevY === null || prevY === undefined) {
            accumulator.push({
                x: curX,
                y: [curY, extremumTemp],
            });
        }

        if (prevY !== undefined && prevY !== null && !prevExcursion) {
            accumulator.push(
                {
                    x: lerpX([prevX, prevY], [curX, curY], extremumTemp),
                    y: [extremumTemp, extremumTemp],
                },
            );
        }

        accumulator.push({
            x: curX,
            y: [curY, extremumTemp],
        });

        if (nextY !== undefined && nextY !== null && !nextExcursion) {
            accumulator.push(
                {
                    x: lerpX([curX, curY], [nextX, nextY], extremumTemp),
                    y: [extremumTemp, extremumTemp],
                },
            );
            accumulator.push(
                {
                    x: lerpX([curX, curY], [nextX, nextY], extremumTemp) + 10,
                    y: [null, null],
                },
            );
        }

        return accumulator;
    }, []).filter((it, i, data) => !(i === data.length - 1 && it.y[0] === null));
};
