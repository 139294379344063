import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            padding: '0 55px 0 55px',
            boxSizing: 'border-box',
        },
        title: {
            paddingTop: 20,
            textAlign: 'center',
            font: '48px Roboto, sans-serif',
            fontWeight: 100,
            color: '#000000DE',
        },
        controls: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 'inherit',
        },
        control: {
            width: '100%',
            height: 80,
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '20px 15px 20px 15px',
            background: theme.palette.primary['deepBlue'],
            textDecoration: 'none',
            borderRadius: 4,
            textAlign: 'center',
            font: '28px Roboto Medium, sans-serif',
            fontWeight: 300,
            letterSpacing: '2.49px',
            color: theme.palette.common.white,
        },
    };
});

export default useStyles;
