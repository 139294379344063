import { TFunction } from 'react-i18next';

export const TEMPERATURE_RANGES = {
    CRT: 'CRT (15 °C to 25 °C)',
    C: 'COL (2°C to 8°C)',
    F: 'F (-25°C to -15°C)',
    DF: 'DF (-80°C to -60°C)',
};
export const TEMPERATURE_RANGES_T = (t:TFunction = () => 'to') => ({
    CRT: `CRT (15 °C ${t('SENSOR_DATA.TO')} 25 °C)`,
    C: `COL (2°C ${t('SENSOR_DATA.TO')} 8°C)`,
    F: `F (-25°C ${t('SENSOR_DATA.TO')} -15°C)`,
    DF: `DF (-80°C ${t('SENSOR_DATA.TO')} -60°C)`,
});
export const INCOTERMS = {
    EXW: 'Ex Works',
    FCA: 'Free Carrier',
    FAS: 'Free Alongside Ship',
    FOB: 'Free On Board',
    CFR: 'Cost And Freight',
    CIF: 'Cost, Insurance And Freight',
    CPT: 'Carriage Paid To',
    CIP: 'Carriage Insurance Paid To',
    DATA: 'Delivered At Terminal',
    DAP: 'Delivered At Place',
    DDP: 'Delivered Duty Paid',
};

export const TEMPERATURE_RANGES_OPTIONS = {
    CRT: 'CRT',
    C: 'COL',
    F: 'F',
    DF: 'DF',
};

export const TEMPERATURE_RANGES_VALUES = {
    CRT: {
        tempRangeMin: 15,
        tempRangeMax: 25,
    },
    C: {
        tempRangeMin: 2,
        tempRangeMax: 8,
    },
    F: {
        tempRangeMin: -25,
        tempRangeMax: -15,
    },
    DF: {
        tempRangeMin: -80,
        tempRangeMax: -60,
    },
};
