/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, useMemo } from 'react';

import { useTheme } from '@material-ui/core';
import {
    getLineStyle,
    getNumberWidth,
    getTempMaxStyle,
    getTempMinStyle,
    getTempRulerStyle,
    maxRangeStyle,
    minRangeStyle,
} from './lib';
import { SkycellThemeInterface } from '../../themes/skycellThemeInterface';

type Props = {
    temperatureMax: number,
    temperatureMin: number,
    temperatureRangeMax: number,
    temperatureRangeMin: number,
    grid?: boolean,
    temperatureCheckStatus?: string,
    isNoStyleText?: boolean,
}

const TemperatureCheck = ({
    temperatureMax,
    temperatureMin,
    temperatureRangeMax,
    temperatureRangeMin,
    grid = true,
    temperatureCheckStatus = null,
    isNoStyleText = false,
} : Props) => {
    const theme = useTheme<SkycellThemeInterface>();
    const gradation = useMemo(() => {
        const lineCount = temperatureRangeMax - temperatureRangeMin === 6 ? 7 : 11;

        return [...Array(lineCount).keys()];
    }, [temperatureRangeMax, temperatureRangeMin]);

    const itemsCountSeparatedByLines = temperatureRangeMax - temperatureRangeMin === 6 ? 6 : 10;
    const scale = 150 / itemsCountSeparatedByLines;
    const temperatureMinOffset = temperatureMin === null
        ? -50
        : Math.min(((temperatureRangeMin - temperatureMin) * scale), scale * 2);
    const offsetToLeft = -180 - getNumberWidth(temperatureMin) - temperatureMinOffset;
    const temperatureMaxOffset = temperatureMax === null
        ? -50
        : Math.min(((temperatureMax - temperatureRangeMax) * scale), scale * 2);
    const width = 150 + temperatureMinOffset + temperatureMaxOffset;

    const mainStyle = useMemo<CSSProperties>(() => ({
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        position: 'relative',
        ...(grid && {
            left: `${offsetToLeft}px`,
            marginRight: `-${width + 6}px`,
        }),
        ...(!grid && {
            width: '100%',
        }),
        height: '16px',
    }), [offsetToLeft, width]);
    const noData = useMemo(() => temperatureCheckStatus === 'NO_DATA', [temperatureCheckStatus]);

    return (
        <>
            {
                grid && (
                    <div style={{ display: 'flex', overflow: 'hidden' }}>
                        <div style={minRangeStyle}>{`${temperatureRangeMin?.toFixed(1)}°C`}</div>
                        {
                            gradation.map((_, i) => (
                                <div
                                    key={`line-${i}`}
                                    style={getLineStyle(gradation.length, gradation.length === i + 1)}
                                />
                            ))
                        }
                        <div style={maxRangeStyle}>{`${temperatureRangeMax?.toFixed(1)}°C`}</div>
                    </div>
                )
            }
            <div
                style={mainStyle}
            >
                <div
                    style={
                        isNoStyleText
                            ? {}
                            : getTempMinStyle(temperatureMin, temperatureRangeMin, temperatureRangeMax, noData)
                    }
                >
                    {temperatureMin === null ? 'n/a' : `${temperatureMin?.toFixed(1)}°C`}
                </div>
                <div style={
                    getTempRulerStyle(
                        width,
                        temperatureMin,
                        temperatureMax,
                        temperatureRangeMin,
                        temperatureRangeMax,
                        grid,
                        noData,
                        theme,
                    )
                }
                />
                <div
                    style={
                        isNoStyleText
                            ? {}
                            : getTempMaxStyle(temperatureMax, temperatureRangeMin, temperatureRangeMax, noData)
                    }
                >
                    {temperatureMax === null ? 'n/a' : `${temperatureMax?.toFixed(1)}°C`}
                </div>
            </div>
        </>
    );
};

export default TemperatureCheck;
