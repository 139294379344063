import ErrorScreenMultipurpose from 'app/components/ErrorScreenMultipurpose';
import LoadingSkyCell from 'app/components/LoadingSkyCell';
import { AuthContext } from 'app/context/AuthContext';
import useUserRoles from 'app/hooks/useUserRoles';
import { ROLES } from 'app/utils/constants';
import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import MainMenu from 'app/screens/MainMenu';
import Layout from 'app/components/Layout';
import ContainerValidationErrorMessage from 'app/components/ContainerValidationErrorMessage';

import Process from 'app/screens/Process';
import getEnviroment from 'app/utils/getEnviroment';

const Root = () => {
    const {
        path,
    } = useRouteMatch();

    const roles = useUserRoles();
    const { userInfo } = useContext(AuthContext);

    const hasRoles = useMemo(() => {
        return roles.includes(ROLES.SKYSHIP_EVIDENCE_COLLECTION)
            || roles.includes(ROLES.SKYSHIP_TEMPERATURE_READOUT);
    }, [roles]);

    const { t } = useTranslation();

    useEffect(() => {
        const env = getEnviroment();

        if (env) {
            document.title = `SkyMind ${env}`;
        }
    }, []);

    if (!userInfo.email) {
        return <Layout><LoadingSkyCell /></Layout>;
    }
    return (
        <Layout>
            <Switch>
                <Route
                    exact
                    path="/no-access-rights"
                >
                    <ErrorScreenMultipurpose
                        error={{
                            errorTitle: t('ERROR.ACCESS_DENIED'),
                            errorMessage: t('ERROR.ACCESS_DENIED_DESC'),
                            icon: 'lock',
                        }}
                    />
                </Route>

                <Route
                    exact
                    path={path}
                >
                    <MainMenu hasRoles={hasRoles} />
                </Route>

                <Route
                    path={`${path}:process`}
                >
                    {!hasRoles && (
                        <ErrorScreenMultipurpose
                            error={{
                                errorTitle: t('ERROR.ACCESS_DENIED'),
                                errorMessage: t('ERROR.ACCESS_DENIED_DESC'),
                                icon: 'lock',
                            }}
                        />
                    )}
                    {hasRoles && (
                        <Process />
                    )}
                </Route>

                <Route
                    path={`${path}notfound/:id`}
                >
                    <ContainerValidationErrorMessage />
                </Route>
            </Switch>
        </Layout>
    );
};

export default Root;
