import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3),
        color: '#000000DE',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        '@media (max-aspect-ratio: 9/17)': {
            padding: '60px 30px 24px 30px',
            gap: '30px',
        },
    },
    welcome: {
        fontSize: 34,
        fontFamily: 'Roboto',
        textTransform: 'uppercase',
    },
    text: {
        fontSize: 24,
        fontFamily: 'Roboto',
    },
    textContainer: {
    },
    image: {
        width: '80%',
        maxWidth: '500px',
        height: 'auto',
        objectFit: 'contain',
        alignSelf: 'center',
        marginBottom: '8vh',
    },
    buttonHolder: {
        marginTop: 10,
        textAlign: 'center',
        paddingBottom: 50,
    },
}));
