export const dateTimeISO = 'DD.MM.YYYY HH:mm ZZ';
export const dateTimeISODate = 'YYYY-MM-DDTHH:mm:ss.sssZZ';
export const dateTimeMask = 'DD.MM.YYYY THH:mm ZZ';
export const dateTimeMaskUTC = 'YYYY-MM-DDTHH:mm:ssZ';
export const dateTimeMaskTimestamp = 'DD.MM.YYYY THH:mm:ss ZZ';
export const dateTimeMaskTimestampUTC = 'DD.MM.YYYY THH:mm:ss';

export const TemperatureIcons = {
    C: 'icons/temperature_C.svg',
    CRT: 'icons/temperature_CRT.svg',
    DF: 'icons/temperature_DF.svg',
    F: 'icons/temperature_F.svg',
};

export const ICONS = {
    circle_left: 'icons/circle_left.svg',
    circle_right: 'icons/circle_right.svg',
    circle_minus: 'icons/circle_minus.svg',
    circle_plus: 'icons/circle_plus.svg',
    home_gray: 'icons/home_gray.svg',
    skycellHeaderLogo: 'images/logo/logo_symbol_white.svg',
    right_arrow_blue: 'icons/right_arrow_blue.svg',
    right_arrow_grey: 'icons/right_arrow_grey.svg',
    checked_blue: 'icons/checked_blue.svg',
    dots_round_grey: 'icons/dots_round_grey.svg',
    tickmark_blue: 'icons/tickmark_blue.svg',
    cross: 'icons/cross.svg',
    zoomIn: 'icons/zoomIn.svg',
    zoomOut: 'icons/zoomOut.svg',
    panLeft: 'icons/panLeft.svg',
    panRight: 'icons/panRight.svg',
    zoomReset: 'icons/zoomReset.svg',

};
export const IMAGES = {
    sleeping: 'skyship/images/sleeping.svg',
};

export const TEMPERATURE_RANGES = {
    CRT: 'CRT (15 °C - 25 °C)',
    C: 'C (2°C - 8°C)',
    F: 'F (-25°C - 15°C)',
    DF: 'DF (-80°C - 60°C)',
};

export enum TEMP_RANGE_CHECK {
    OK = 'OK',
    NO_DATA = 'NO_DATA',
    FAILED_TEMPERATURE= 'FAILED_TEMPERATURE'
}

export const PALLET_STEPS = [
    'PALLET', 'PALLET_1', 'PALLET_2',
];

export enum ROLES {
    SKYSHIP_EVIDENCE_COLLECTION='SKYSHIP_EVIDENCE_COLLECTION',
    SKYSHIP_TEMPERATURE_READOUT='SKYSHIP_TEMPERATURE_READOUT'
}
