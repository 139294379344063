import ErrorScreenMultipurpose from 'app/components/ErrorScreenMultipurpose';
import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useFetchProcesses from 'app/hooks/useFetchProcesses';

import LoadingSkyCell from 'app/components/LoadingSkyCell';
import useStyles from './MainMenu.style';

export const SSI_PROCESS_EXECUTION = 'SSI_PROCESS_EXECUTION';

const MainMenu = ({ hasRoles }) => {
    const classes = useStyles();
    const {
        t,
    } = useTranslation();

    const {
        processes,
        isProcessesLoading,
    } = useFetchProcesses(hasRoles);

    if (isProcessesLoading || !processes) {
        return (
            <LoadingSkyCell />
        );
    }

    return (
        <>
            {
                !hasRoles && (
                    <ErrorScreenMultipurpose
                        error={{
                            errorTitle: t('ERROR.ACCESS_DENIED'),
                            errorMessage: t('ERROR.ACCESS_DENIED_DESC'),
                            icon: 'lock',
                        }}
                    />
                )

            }

            {
                hasRoles && <Redirect to="CHECK_TEMPERATURE" />
            }
            {
                hasRoles && (
                    <div
                        className={classes.container}
                    >
                        <h1
                            className={classes.title}
                        >
                            {t('I_WANT_TO')}
                        </h1>

                        <div
                            className={classes.controls}
                        >
                            {
                                processes?.map(({
                                    processLabel,
                                    processName,
                                    process,
                                }) => {
                                    return (
                                        <NavLink
                                            key={processName}
                                            className={classes.control}
                                            to={process}
                                        >
                                            {t(processLabel).toUpperCase()}
                                        </NavLink>
                                    );
                                })
                            }
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default MainMenu;
