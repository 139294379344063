import React, { useEffect } from 'react';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Cargo, Container, TempRangeStatus } from 'app/utils/dataTypes';
import TemperatureCheck from 'app/shared-components/TemperatureCheck';
import useStyles from '../TemperatureInfo.style';
import RangeCheckResult from './RangeCheckResult';
import TempRange from '../../../../shared-components/TempRange';
import { ContainerMinMaxTemp } from '../../../TemperatureChart/TemperatureChart';

type Props = {
    serialNumber: string,
    shipmentNumber: string,
    selectedAsset: Cargo,
    container: Container,
    fromDate: string,
    toDate: string,
    fromTooltip: string,
    toTooltip: string,
    tempCheckResponse?: TempRangeStatus,
    buttonPressed: string,
    temperatureRange: string,
}

const ShipmentCard = ({
    serialNumber = '',
    shipmentNumber = '',
    selectedAsset,
    temperatureRange,
    tempCheckResponse,
}: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    useEffect(() => {
        const handler = () => {
            // setTooltipState({ from: false, to: false });
        };

        document.addEventListener('click', handler);
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);

    return (
        <>
            <div className={classes.shimpentCardHolder}>
                <div className={classes.shipmentCardContainer}>
                    <div className={classes.shipmentCardTitle}>
                        <span>
                            <b>
                                {t('CONTAINER')}
                                :
                            </b>
                            <span style={{ color: theme.palette.common.black }}>{serialNumber}</span>
                        </span>
                        <div style={{ flex: 1 }} />
                        <span>
                            <b>
                                {t('TEMPERATURE.SHIPMENT')}
                                :
                            </b>
                            <span style={{ color: theme.palette.common.black }}>{shipmentNumber}</span>
                        </span>
                    </div>
                    <div className={classes.shipmentCardBody}>
                        <>
                            <span>{t('TEMPERATURE.ALLOWED_RANGE')}</span>
                            <span style={{ color: theme.palette.common.black }}>
                                <TempRange tempRange={temperatureRange} />
                            </span>
                        </>
                        <>
                            <span>{t('TEMPERATURE.ACTUAL_RANGE')}</span>
                            <span style={{ color: theme.palette.common.black }}>
                                <TemperatureCheck
                                    temperatureRangeMin={ContainerMinMaxTemp[temperatureRange][0]}
                                    temperatureRangeMax={ContainerMinMaxTemp[temperatureRange][1]}
                                    temperatureMin={selectedAsset?.temperatureCheckResult?.temperatureMin}
                                    temperatureMax={selectedAsset?.temperatureCheckResult?.temperatureMax}
                                    grid={false}
                                />
                            </span>
                        </>
                        <RangeCheckResult
                            temperatureStatus={tempCheckResponse}
                            bold
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShipmentCard;
