import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './TemperatureWelcomeScreen.style';
import SkyMindButton from '../../SkyMindButton';

type Props = {
    title?: string,
    value?: {
        palletCode: string
    },
    subTitle?: string,
    label: string,
    skippable?: boolean,
    onChange: (...arg: any) => any,
    onSubmit: (...arg: any) => any,
}

const TemperatureWelcomeScreen = (
    {
        onSubmit,
    }: Props,
) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <div className={classes.container}>
                <div className={classes.welcome}>
                    {t('WELCOME')}
                </div>
                <div className={classes.text}>{t('SCAN_CHECK_TEMPERATURE')}</div>
                <div style={{ flex: 1 }} />
                <img
                    className={classes.image}
                    src="/img/container_welcome.png"
                    alt="Container"
                />
                <div style={{ flex: 1 }} />
                <SkyMindButton
                    text={t('LETS_GO')}
                    onClick={() => onSubmit()}
                    fullWidth
                />
            </div>
        </>
    );
};

export default TemperatureWelcomeScreen;
