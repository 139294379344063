import moment from 'moment';
import { Theme } from '@material-ui/core';

type TooltipOptions = {
    series: any[];
    seriesIndex: number;
    dataPointIndex: number;
    w: {
        config: any,
        globals: {
            seriesNames: any[],
            seriesX: any[],
            seriesRange: any[],
            seriesColors: any[],
            initialSeries: {
                name: string,
                opacity: number,
                type: string,
                color: string,
                data: any[],
                showValueInTooltip: boolean,
            }[],
        },
    };
}

const addSeries = ({
    root,
    dotStyle,
    seriesTitleStyle,
    dividerStyle,
    valueStyle,
    dataRowStyle,
    value,
    title,
    seriesColor = null,
}) => {
    const dataRow = root.appendChild(document.createElement('div'));

    if (seriesColor) {
        const dot = dataRow.appendChild(document.createElement('div'));

        Object.assign(dot.style, { ...dotStyle, background: seriesColor });
    }
    const text = dataRow.appendChild(document.createElement('div'));
    const divider = dataRow.appendChild(document.createElement('div'));
    const valueHolder = dataRow.appendChild(document.createElement('div'));

    text.innerText = title;
    valueHolder.innerText = value;

    Object.assign(text.style, seriesTitleStyle);
    Object.assign(divider.style, dividerStyle);
    Object.assign(valueHolder.style, valueStyle);
    Object.assign(dataRow.style, dataRowStyle);
    return dataRow;
};

const getStyles = (theme) => ({
    tooltipStyle: {
        color: theme.palette.common.black,
        fontSize: '12px',
        minWidth: '255px',
        overflow: 'hidden',
    },
    headerStyle: {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: '14px',
        letterSpacing: '0.03em',
        color: theme.palette.common.black,
        width: '100%',
        padding: '20px 20px 5px 20px',
    },
    contentStyle: {
        padding: '0px 20px 15px 20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
    },
    dataRowStyle: {
        marginTop: '15px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    dotStyle: {
        marginLeft: '4px',
        width: '16px',
        height: '16px',
        borderRadius: '50%',
    },
    seriesTitleStyle: {
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        fontSize: '13px',
        letterSpacing: '0.005em',
        color: theme.palette.common.black,
    },
    dividerStyle: {
        flex: 1,
    },
    valueStyle: {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: '13px',
        letterSpacing: '0.03em',
        color: theme.palette.common.black,
    },
});

type Props = {
    opts: TooltipOptions,
    theme: Theme,
    trans: Function,
}
export const generateTooltip = ({
    opts, theme, trans,
}: Props) => {
    const { seriesIndex, dataPointIndex, w } = opts;

    const {
        globals,
    } = w;
    const {
        seriesNames,
        seriesX,
        seriesRange,
        seriesColors,
        initialSeries,
    } = globals;

    const {
        tooltipStyle,
        headerStyle,
        contentStyle,
        dataRowStyle,
        dotStyle,
        seriesTitleStyle,
        dividerStyle,
        valueStyle,
    } = getStyles(theme);

    const tooltip = document.createElement('div');
    const header = tooltip.appendChild(document.createElement('div'));
    const content = tooltip.appendChild(document.createElement('div'));

    Object.assign(tooltip.style, tooltipStyle);
    Object.assign(header.style, headerStyle);
    Object.assign(content.style, contentStyle);
    header.innerHTML = moment(seriesX[seriesIndex][dataPointIndex]).format('YYYY-MM-DD HH:mm');

    seriesNames.forEach((seriesName, i) => {
        const selectedXValue = seriesX[seriesIndex][dataPointIndex];
        const value = seriesRange[i]?.find(it => it?.x === selectedXValue) || {};
        const initialSeriesConfig = initialSeries[i];

        const {
            y1,
        } = value?.y?.[0] || {};

        if (y1 !== undefined && y1 !== null) {
            addSeries({
                root: content,
                dotStyle,
                seriesTitleStyle,
                dividerStyle,
                valueStyle,
                dataRowStyle,
                value: initialSeriesConfig.showValueInTooltip === false
                    ? seriesName : `${y1}`,
                title: initialSeriesConfig.showValueInTooltip === false
                    ? trans('TRACK_AND_TRACE.LOCATION') : seriesName,
                seriesColor: seriesColors[i],
            });
        }
    });

    return tooltip.outerHTML;
};
