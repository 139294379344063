import { makeStyles } from '@material-ui/core';
import { SkycellThemeInterface } from 'app/themes/skycellThemeInterface';

const useStyles = makeStyles((theme: SkycellThemeInterface) => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            boxSizing: 'border-box',
            padding: '0px 20px 0px 20px',
            height: '100%',
            width: '100%',
            animation: '$appear 800ms ease forwards',
        },
        '@keyframes appear': {
            from: { opacity: 0 },
            to: { opacity: 1 },
        },
        header: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
            justifyContent: 'center',
            padding: '10px 30px',
        },
        title: {
            color: theme.palette.common.black,
            fontFamily: 'Roboto',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0.18px',
            marginBottom: '16px',
        },
        description: {
            color: theme.palette.secondary[800],
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.25px',
        },
        numberTitle: {
            color: theme.palette.primary.pastelBlue,
            textAlign: 'center',
            fontFamily: 'Roboto',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0.18px',
            marginBottom: '16px',
        },
        img: {
            height: 160,
            width: 160,
            marginBottom: '43px',
            [theme.breakpoints.down('xs')]: {
                width: 135,
                height: 135,
            },
        },
        btn: {
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 46,
            backgroundColor: theme.palette.primary['deepBlue'],
            color: theme.palette.common.white,
            textAlign: 'center',
            border: 'none',
            borderRadius: '8px',
            boxShadow: '0px 1px 3px $shadow_black',
            margin: '28px 38px',
            boxSizing: 'border-box',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '16px',
            letterSpacing: '1.25px',
            textTransform: 'uppercase',

        },
    };
});

export default useStyles;
