import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import SwitchWithLabel from 'app/shared-components/SwitchWithLabel';
import useStyles from './MeasuredTemperatureSwitch.style';

type Props = {
    value: boolean,
    onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void,
    className?: string,
    disabled?: boolean,
    color?: 'primary' | 'secondary',
    size?: 'small' | 'medium',
}

const MeasuredTemperatureSwitch = ({
    value,
    onChange,
    className = '',
    disabled = false,
    color = 'primary',
    size = 'medium',
}: Props) => {
    const { t } = useTranslation();

    const classes = useStyles();

    const customClass = [classes.switchWrapper, disabled ? classes.disabled : '', className].join(' ');

    return (
        <SwitchWithLabel
            title={t('TEMPERATURE.MEASURED_TEMPERATURE_ONLY')}
            value={value}
            onChange={onChange}
            className={customClass}
            color={color}
            size={size}
            disabled={disabled}
            switchClassName={classes.switch}
        />
    );
};

export default MeasuredTemperatureSwitch;
