import { makeStyles } from '@material-ui/core';
import { SkycellThemeInterface } from '../../themes/skycellThemeInterface';

export const useStyles = makeStyles((theme: SkycellThemeInterface) => ({
    button: {
        border: 'none',
        borderRadius: '8px',
        padding: '12px 16px 12px 16px',
        fontFamily: 'Roboto',
        fontSize: '14px',
        textTransform: 'uppercase',
        fontWeight: 500,
        letterSpacing: 1.5,
        minWidth: '50px',
        height: 45,
        '&:disabled': {
            opacity: 0.5,
        },
    },
    filled: {
        background: theme.palette.primary.deepBlue,
        color: theme.palette.common.white,
    },
    outline: {
        background: theme.palette.common.white,
        color: theme.palette.primary.deepBlue,
        border: `1px solid  ${theme.palette.primary.deepBlue}`,

    },
    ghost: {

    },
    fullWidth: {
        width: '100%',
        whiteSpace: 'nowrap',
    },
}));
