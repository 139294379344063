import { CSSProperties } from 'react';
import { SkycellThemeInterface } from '../../themes/skycellThemeInterface';

export const minRangeStyle: CSSProperties = {
    font: 'normal normal normal 12px/14px Roboto',
    letterSpacing: '0.37px',
    position: 'relative',
    zIndex: 2,
    width: '31px',
    textAlign: 'end',
    bottom: '0px',
    marginTop: 'auto',
    right: '-13px',
};

export const maxRangeStyle: CSSProperties = {
    font: 'normal normal normal 12px/14px Roboto',
    letterSpacing: '0.37px',
    position: 'relative',
    zIndex: 2,
    width: '31px',
    textAlign: 'end',
    bottom: '0px',
    marginTop: 'auto',
    left: '-19px',
};

export const getLineStyle = (lineCount: number, lastElement = false): CSSProperties => {
    return {
        borderLeft: '1px dashed #B7B7B7',
        height: '100%',
        marginRight: lastElement
            ? '0px'
            : lineCount === 7 ? '24px' : '14px',
    };
};

export const getNumberWidth = (temperature: number) => {
    const negativeNumber = temperature < 0 ? 4 : 0;
    const absolute = Math.abs(temperature);

    if (absolute >= 0 && absolute < 10 && absolute % 1 === 0) {
        return 32 + negativeNumber;
    } else if (absolute >= 0 && absolute < 10) {
        return 43 + negativeNumber;
    } else if (absolute >= 10 && absolute % 1 === 0) {
        return 43 + negativeNumber;
    }
    return 50 + negativeNumber;
};

export const getTempMinStyle = (
    tempMin: number,
    tempMinRange: number,
    tempMaxRange: number,
    noData: boolean,
): CSSProperties => {
    const inRange = tempMin >= tempMinRange && tempMin <= tempMaxRange;
    const backgroundColor = tempMin === null
        ? '#EFEFEF'
        : (inRange) ? '#EAF9EF' : '#FFE5E5';
    const color = tempMin === null
        ? '#747474'
        : inRange ? '#17921F' : '#D44848';

    return {
        background: noData ? 'transparent' : `${backgroundColor} 0% 0% no-repeat padding-box`,
        borderRadius: '4px',
        color: noData ? '#747474' : color,
        padding: '0 4px',
        textAlign: 'right',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.37px',
        opacity: 1,
    };
};

export const getTempMaxStyle = (
    tempMax: number,
    tempMinRange: number,
    tempMaxRange: number,
    noData: boolean,
): CSSProperties => {
    const inRange = tempMax >= tempMinRange && tempMax <= tempMaxRange;

    const backgroundColor = tempMax === null
        ? '#EFEFEF'
        : inRange ? '#EAF9EF' : '#FFE5E5';
    const color = tempMax === null
        ? '#747474'
        : inRange ? '#17921F' : '#D44848';

    return {
        background: noData ? 'transparent' : `${backgroundColor} 0% 0% no-repeat padding-box`,
        borderRadius: '4px',
        color: noData ? '#747474' : color,
        padding: '0 4px',
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.37px',
        opacity: 1,
    };
};

const getTempRulerStyleBackground = (minInRange: boolean, maxInRange: boolean) => {
    if (minInRange && maxInRange) {
        return '#E0F7FF 0% 0% no-repeat padding-box';
    } else if (!minInRange && !maxInRange) {
        return '#FFE5E5 0% 0% no-repeat padding-box';
    }

    return `transparent linear-gradient(${
        minInRange ? 270 : 90}deg, #FFE5E5 0%, #E0F7FF 100%) 0% 0% no-repeat padding-box`;
};

export const getTempRulerStyle = (
    width: number,
    tempMin: number,
    tempMax: number,
    tempMinRange: number,
    tempMaxRange: number,
    grid: boolean,
    noData: boolean,
    theme: SkycellThemeInterface,
): CSSProperties => {
    const minInRange = tempMin !== null && tempMin >= tempMinRange && tempMin <= tempMaxRange;
    const maxInRange = tempMax !== null && tempMax <= tempMaxRange && tempMax >= tempMinRange;
    const borderLeftColor = tempMin === null
        ? theme.palette.secondary[500]
        : minInRange ? theme.palette.primary[400] : theme.palette.common.red;
    const borderRightColor = tempMax === null
        ? theme.palette.secondary[500]
        : maxInRange ? theme.palette.primary[400] : theme.palette.common.red;
    const background = tempMin === null && tempMax === null
        ? '#EFEFEF 0% 0% no-repeat padding-box'
        : getTempRulerStyleBackground(minInRange, maxInRange);

    return {
        zIndex: 5,
        width: !grid ? '50px' : `${width + 3}px`,
        height: '14px',
        borderLeft: `6px solid ${noData ? theme.palette.secondary[600] : borderLeftColor}`,
        borderRight: `6px solid ${noData ? theme.palette.secondary[600] : borderRightColor}`,
        borderRadius: '3px',
        background: noData ? theme.palette.secondary[200] : background,
    };
};
