import { useQuery } from 'react-query';
import useSecureBackendEndpoints from '../useSecureBackendEndpoints';

const useContactInfo = (email, ...options) => {
    const {
        Get: getContactData,
    } = useSecureBackendEndpoints('users/email').requests;

    return useQuery({
        queryKey: [
            'getContactInfo',
        ],
        queryFn: () => getContactData(email).then(it => it.data),
        enabled: Boolean(email),
        refetchOnWindowFocus: false,
        retry: false,
        ...options,
    });
};

export default useContactInfo;
