import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import getAssetBlobUrl from 'app/utils/getGlobalAssetUrl';

import { useTranslation } from 'react-i18next';
import useStyles from './Header.style';
import AppDrawerContext from '../../context/AppDrawerContext';
import HeaderNavigationContext from '../../context/HeaderNavigationContext';

const Header = () => {
    const classes = useStyles();

    const history = useHistory();
    const {
        setOpen: setDrawerOpen,
    } = useContext(AppDrawerContext);

    const { t } = useTranslation();
    const handleClickMenu = useCallback(() => {
        setDrawerOpen(true);
    }, [history]);
    const handleHomeClick = useCallback(() => {
        history.push('/');
    }, [history]);

    const homeIcon = getAssetBlobUrl('icons/burger.svg');

    const headerNavigationContext = useContext(HeaderNavigationContext);

    return (
        <header
            className={classes.header}
        >
            {
                headerNavigationContext.onSkip && (
                    <button
                        type="button"
                        onClick={headerNavigationContext.onSkip}
                        className={classes.headerButton}
                    >
                        {t('SKIP')}
                    </button>
                )
            }

            <div
                className={classes.logoWrapper}
                onClick={handleHomeClick}
            >
                <img
                    className={classes.headerIcon}
                    src="logo.svg"
                    alt="logo"
                />
                <span>SkyMind</span>
            </div>

            <div style={{ flex: '100%' }} />

            <div className={classes.btns}>
                <button
                    type="button"
                    className={classes.menu}
                    onClick={handleClickMenu}
                >
                    <img
                        className={classes.icon}
                        src={homeIcon}
                        alt="home"
                    />
                </button>
            </div>

        </header>
    );
};

export default Header;
