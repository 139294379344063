import React, { MouseEventHandler } from 'react';
import { useStyles } from './SkyMindButton.styles';

type Props = {
    type?: 'filled' | 'outline' | 'ghost',
    text: string,
    disabled?: boolean,
    onClick?: MouseEventHandler<HTMLButtonElement>,
    fullWidth?: boolean,
}
const SkyMindButton = ({
    type = 'filled',
    text,
    disabled = false,
    onClick = () => {},
    fullWidth = false,
}: Props) => {
    const classes = useStyles();

    return (
        <button
            type="button"
            disabled={disabled}
            onClick={onClick}
            className={[
                classes.button,
                classes[type],
                fullWidth ? classes.fullWidth : '',
            ].join(' ')}
        >
            {text}
        </button>
    );
};

export default SkyMindButton;
