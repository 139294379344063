import React, { useCallback, useEffect, useState } from 'react';

import useAssetNumber from 'app/hooks/useAssetNumber';

import LoadingSkyCell from 'app/components/LoadingSkyCell';
import { useGetAttachment } from 'app/hooks/useSaveAttachment/useSaveAttachment';
import PhotoPickerRaw from '../../PhotoPickerRaw';
import SavingError from '../../SavingError';
import YesNoDialog from './YesNoDialog';

type Props = {
    type: string,
    title: string,
    subTitle: string,
    label: string,
    skippable?: boolean,
    value: any,
    process: string,
    onChange: (...args: any) => any,
    onSubmit: (...args: any) => any,
}

const APP_NAME = 'SkyMind';
const IMAGES_LIMIT = 10;
const PictureMultiple = ({
    type,
    title,
    subTitle,
    label,
    skippable = false,
    process,
    onChange,
    onSubmit,
    value = null,
}: Props) => {
    const [loading, setLoading] = useState(false);
    const [imageData, setImageData] = useState({
        pictures: [],
        proceed: false,
    });
    const [currentPicture, setCurrentPicture] = useState(null);
    const [assetNumber] = useAssetNumber();
    const [saveAttachmentError, setSaveAttachmentError] = useState(null);

    const getAttachment = useGetAttachment();
    const handleChange = useCallback((image) => {
        setCurrentPicture(image);
    }, [setCurrentPicture]);

    const handleSubmit = useCallback(async (proceed = false) => {
        const [file, fileName] = [currentPicture, `${APP_NAME}-${process}-AssetNb_${assetNumber}-${type}-${label}.png`];

        setSaveAttachmentError(null);
        setLoading(true);

        let pictureId;

        try {
            pictureId = await getAttachment({ file, fileName });
        } catch (e) {
            setSaveAttachmentError({ proceed });
            console.error(e);
            return;
        }
        setImageData(prev => ({
            pictures: [...prev.pictures, { id: pictureId }],
            proceed,
        }));
        setLoading(false);
        setCurrentPicture(null);
    }, [
        currentPicture,
        process,
        assetNumber,
        type,
        label,
    ]);

    useEffect(() => {
        const { pictures, proceed } = imageData;

        if (proceed) {
            onChange({
                target: {
                    value: {
                        picturesWithComments: pictures,
                    },
                },
            }, label);
        }
    }, [imageData]);

    useEffect(() => {
        if (value) {
            onSubmit();
        }
    }, [value]);

    useEffect(() => {
        if (imageData?.pictures?.length >= IMAGES_LIMIT - 1) {
            handleSubmit(true).then();
        }
    }, [imageData?.pictures?.length]);

    if (saveAttachmentError) {
        return (
            <SavingError
                title="CANT_SAVE_PICTURES"
                onTryAgain={() => handleSubmit(saveAttachmentError.proceed)}
            />
        );
    }

    if (loading) {
        return <LoadingSkyCell />;
    }

    if (currentPicture) {
        return (
            <YesNoDialog
                onYes={() => handleSubmit(false)}
                onNo={() => handleSubmit(true)}
            />
        );
    }

    return (
        <>
            <PhotoPickerRaw
                label={label}
                value={currentPicture}
                setValue={setCurrentPicture}
                onAccept={handleChange}
                onSkip={(skippable && imageData.pictures.length === 0) ? onSubmit : null}
                title={title}
                subTitle={subTitle}
            />
        </>
    );
};

export default PictureMultiple;
